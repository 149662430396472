<template>
  <div
    class="box"
    :style="{ left: left, right: right, top: top, bottom: bottom }"
  >
    <span :class="start ? 'start' : null">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    start: {
      type: Boolean,
      default: false,
    },
    left: {
      type: String,
      default: 'auto',
    },
    right: {
      type: String,
      default: 'auto',
    },
    top: {
      type: String,
      default: 'auto',
    },
    bottom: {
      type: String,
      default: 'auto',
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 42px;
  height: 30px;
  background-image: url(../../../assets/images/dafuweng/box.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  position: absolute;
  span {
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #0a324b;
    transform: skewX(-20deg);
    width: 60%;
    text-align: center;
    display: block;
    line-height: 12px;
    margin-left: -2px;
    &.start {
      color: #cd1f1f;
    }
  }
}
</style>
