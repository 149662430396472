<template>
  <div class="userMap">
    <div class="userImg" v-for="item in list" :key="item.id">
      <img :src="item.headImg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
    },
  },
};
</script>

<style lang="less" scoped>
.userMap {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 25px;
  .userImg {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    border: 2px solid #34627e;
    box-sizing: border-box;
    background: #d1dee6;
    img {
      width: 100%;
      height: 100%;
      transform: rotate(90deg);
    }
  }
}
</style>
