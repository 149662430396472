export default [
  { id: 0, top: '1.76rem', right: '1.73rem', title: '起点' },
  { id: 1, top: '2.72rem', right: '1.73rem', title: '客栈' },
  { id: 2, top: '3.73rem', right: '1.68rem', title: '商行' },
  { id: 3, top: '4.80rem', right: '1.33rem', title: '' },
  { id: 4, top: '5.65rem', right: '1.60rem', title: '机会牌' },
  { id: 5, top: '6.29rem', right: '2.21rem', title: '' },
  { id: 6, top: '7.17rem', right: '2.40rem', title: '茶馆' },
  { id: 7, top: '8.27rem', right: '1.65rem', title: '道观' },
  { id: 8, top: '9.47rem', right: '0.96rem', title: '乐坊' },
  { id: 9, top: '10.45rem', right: '0.93rem', title: '' },
  { id: 10, top: '11.25rem', right: '1.17rem', title: '' },
  { id: 11, top: '12.16rem', right: '1.25rem', title: '医馆' },
  { id: 12, top: '12.96rem', right: '1.68rem', title: '机会牌' },
  { id: 13, top: '14.03rem', right: '1.28rem', title: '' },
  { id: 14, top: '14.72rem', right: '1.76rem', title: '店铺' },
  { id: 15, top: '15.73rem', right: '1.49rem', title: '华夏港' },
  { id: 16, top: '16.67rem', right: '1.49rem', title: '华夏港' },
  { id: 17, top: '17.33rem', right: '2.13rem', title: '青楼' },
  { id: 18, top: '17.36rem', right: '2.96rem', title: '牧场' },
  { id: 19, top: '17.79rem', right: '3.76rem', title: '' },
  { id: 20, top: '17.60rem', right: '4.61rem', title: '酒馆' },
  { id: 21, top: '16.51rem', right: '4.99rem', title: '客栈' },
  { id: 22, top: '15.55rem', right: '5.07rem', title: '客栈' },
  { id: 23, top: '14.85rem', right: '4.56rem', title: '机会牌' },
  { id: 24, top: '13.84rem', right: '4.83rem', title: '店铺' },
  { id: 25, top: '12.77rem', right: '4.99rem', title: '皇宫' },
  { id: 26, top: '12.77rem', right: '5.81rem', title: '皇宫' },
  { id: 27, top: '13.41rem', right: '6.24rem', title: '' },
  { id: 28, top: '14.40rem', right: '6.19rem', title: '书院' },
  { id: 29, top: '14.75rem', right: '7.01rem', title: '' },
  { id: 30, top: '15.52rem', right: '7.47rem', title: '赌坊' },
  { id: 31, top: '14.75rem', right: '8.29rem', title: '农田' },
  { id: 32, top: '13.79rem', right: '8.40rem', title: '' },
  { id: 33, top: '12.93rem', right: '8.27rem', title: '茶馆' },
  { id: 34, top: '12.00rem', right: '8.29rem', title: '' },
  { id: 35, top: '11.23rem', right: '7.92rem', title: '矿场' },
  { id: 36, top: '10.45rem', right: '7.63rem', title: '' },
  { id: 37, top: '9.81rem', right: '7.09rem', title: '机会牌' },
  { id: 38, top: '8.93rem', right: '7.01rem', title: '牧场' },
  { id: 39, top: '7.92rem', right: '7.09rem', title: '' },
  { id: 40, top: '7.01rem', right: '6.99rem', title: '异界展示' },
  { id: 41, top: '6.27rem', right: '6.51rem', title: '' },
  { id: 42, top: '5.25rem', right: '6.56rem', title: '书院' },
  { id: 43, top: '4.32rem', right: '6.59rem', title: '书院' },
  { id: 44, top: '4.27rem', right: '7.39rem', title: '商行' },
  { id: 45, top: '3.17rem', right: '7.81rem', title: '机会牌' },
  { id: 46, top: '2.32rem', right: '7.52rem', title: '' },
  { id: 47, top: '1.39rem', right: '7.41rem', title: '寺院' },
  { id: 48, top: '1.12rem', right: '6.59rem', title: '农田' },
  { id: 49, top: '1.47rem', right: '5.73rem', title: '' },
  { id: 50, top: '1.39rem', right: '4.88rem', title: '客栈' },
  { id: 51, top: '0.67rem', right: '4.45rem', title: '' },
  { id: 52, top: '1.36rem', right: '3.65rem', title: '机会牌' },
  { id: 53, top: '1.47rem', right: '2.85rem', title: '' },
  { id: 54, top: '0.72rem', right: '2.45rem', title: '' },
];
