<template>
  <div
    class="map"
    :style="{
      top: item.top,
      right: item.right,
    }"
    v-if="item.list.length"
    @click="$emit('click', item.key)"
  >
    <UserImg :list="list" />
    <img class="mapImg" src="../../../assets/images/dafuweng/map.png" alt="" />
    <div class="user">
      <div class="userImg">
        <img :src="item.list[0].headImg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import UserImg from './userImg.vue';
export default {
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    list() {
      const [_, ...list] = this.item.list;
      return list;
    },
  },
  components: {
    UserImg,
  },
};
</script>

<style lang="less" scoped>
.map {
  position: absolute;
  top: 66px;
  right: 65px;
  width: 40px;
  height: 25px;
  transition: all 0.3s;
  margin-right: -17px;
  display: flex;
  align-items: center;
  justify-content: center;
  .mapImg {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .user {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 25px;
    width: 25px;
    .userImg {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 50%;
      height: 25px;
      width: 25px;
      border: 1px solid #34627e;
      box-sizing: border-box;
      background: #d1dee6;
      img {
        width: 100%;
        height: 100%;
        transform: rotate(90deg);
      }
    }
  }
}
</style>
