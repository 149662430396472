<template>
  <div :style="{ overflow: showTip ? 'hidden' : 'auto', height: '100vh' }">
    <div class="dafuweng">
      <Box
        v-for="item in boxList"
        :key="item.id"
        :title="item.title"
        :start="item.id === 0"
        :top="item.top"
        :right="item.right"
        :bottom="item.bottom"
        :left="item.left"
      />
      <Map
        @click="clickMap"
        v-for="item in mapList"
        :key="item.id"
        :item="item"
      />
      <div class="button">
        <img
          class="buttonBg"
          src="../../assets/images/dafuweng/button.png"
          alt=""
        />
        <div class="content">
          <!-- <p>*剩余3次</p> -->
          <div class="shaizi">
            <img src="../../assets/images/dafuweng/zhishaizi.png" alt="" />
          </div>
        </div>
      </div>
      <div class="group">
        <div class="groupItem" @click="toast">
          <img src="../../assets/images/dafuweng/renwu.png" alt="" />
        </div>
        <div class="groupItem" @click="toast">
          <img src="../../assets/images/dafuweng/gaoshi.png" alt="" />
        </div>
        <div class="groupItem" @click="toast">
          <img src="../../assets/images/dafuweng/beibao.png" alt="" />
        </div>
        <div class="groupItem" @click="toast">
          <img src="../../assets/images/dafuweng/gerenxinxi.png" alt="" />
        </div>
      </div>

      <Overlay :show="showTip" @click="showTip = false"> </Overlay>
      <div v-if="showTip" class="boxBg" @click.stop>
        <i class="close" @click="showTip = false"></i>
        <div class="top">
          <div class="left">
            <div><img :src="userInfo.head_img_url" alt="" /></div>
            <span>{{ userInfo.nickName }}</span>
          </div>
          <div class="title">请选择您在游戏中的角色</div>
          <div class="right"></div>
        </div>
        <div class="middle" v-if="list.length">
          <div class="middleBox">
            <div
              class="groupItem"
              :class="item.id == selectVal.id ? 'active' : null"
              v-for="item in list"
              :key="item.id"
              @click="select(item)"
            >
              <div>
                <img :src="item.listPic" alt="" />
              </div>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div v-else class="info middle">
          暂无可用角色，可在市场购买您要使用的角色
        </div>
        <div class="bottom">
          <div class="left">
            <span v-if="list.length">*游戏开始后，角色不可更改</span>
          </div>
          <div class="right" v-if="list.length">
            <img
              v-if="selectVal.id"
              src="../../assets/images/tianliangle/play.png"
              @click="choiceRole"
              alt=""
            />
            <img
              v-else
              src="../../assets/images/tianliangle/play_disable.png"
              alt=""
            />
          </div>
          <!-- <div class="right" v-else @click="goTo"> -->
          <div class="right" v-else>
            <img src="../../assets/images/dafuweng/goBay_disable.png" alt="" />
          </div>
        </div>
      </div>

      <Overlay :show="showTip1" @click="showTip1 = false"> </Overlay>
      <div v-if="showTip1" class="boxBg1" @click.stop>
        <i class="close" @click="showTip1 = false"></i>
        <div class="bg">
          <div class="title">此位置的所有玩家</div>
          <div class="tab">
            <div class="tabItem">用户</div>
            <div class="tabItem">角色</div>
            <div class="tabItem">到达时间</div>
          </div>
          <div class="content">
            <div class="tab list" v-for="item in userList" :key="item.id">
              <div class="tabItem">
                <div class="left">
                  <img :src="item.user.headImg" alt="" />
                </div>
                <div class="right">{{ item.user.nickName }}</div>
              </div>
              <div class="tabItem">{{ item.goods.name }}</div>
              <div class="tabItem">{{ item.updated_at }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast" v-if="toastText">
      {{ toastText }}
    </div>
  </div>
</template>

<script>
import Box from './components/box.vue';
import { Overlay } from 'vant';
import Map from './components/map.vue';
import boxList from './data';
import { addBridgeForAndroidWebView } from 'sdbridge-android';
export default {
  components: {
    Box,
    Map,
    Overlay,
  },
  data() {
    return {
      toastText: '',
      boxList: boxList,
      top: '66px',
      right: '65px',
      token: null,
      showTip: false,
      showTip1: false,
      userInfo: {},
      list: [],
      selectVal: {},
      mapList: [],
      userList: [],
      bridge: null,
      isAndroid:
        navigator.userAgent.indexOf('Android') > -1 ||
        navigator.userAgent.indexOf('Adr') > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
    };
  },
  mounted() {
    let bridge = window.WebViewJavascriptBridge;
    if (!bridge) {
      console.log('TypeScript正在尝试挂载');
      addBridgeForAndroidWebView();
      //@ts-ignore
      bridge = window.WebViewJavascriptBridge;
      if (bridge) {
        console.log(
          'window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!',
        );
        this.bridge = bridge;
      }
    } else {
      this.bridge = bridge;
      console.log('window.WebViewJavascriptBridge 挂载成功！！');
    }
    this.token = this.$route.query.token;
    this.$api.anyUserinfo({ token: this.token }).then((res) => {
      this.userInfo = res.data;
    });
    this.richPlace();
    this.isChoiceRole();
    this.monopolyInit();
  },
  methods: {
    toast() {
      this.shotToast('暂未开放，敬请期待～');
    },
    async clickMap(key) {
      const res = await this.$api.getPlaceUser({
        token: this.token,
        step: key,
      });
      if (res.code == 0) {
        this.userList = res.data;
        this.showTip1 = true;
      } else {
        this.shotToast(res.msg);
      }
    },
    async richPlace() {
      const res = await this.$api.richPlace({ token: this.token });
      if (res.code == 0) {
        this.boxList = res.data;
      }
    },
    async monopolyInit() {
      const res = await this.$api.monopolyInit({ token: this.token });
      const list = [];
      for (const [key, val] of Object.entries(res.data)) {
        list.push({
          top: this.boxList[key].top,
          right: this.boxList[key].right,
          list: val,
          key: key,
        });
      }
      this.mapList = list;
    },
    async userHaveRole() {
      const res = await this.$api.userHaveRole({ token: this.token });
      this.list = res.data;
      this.showTip = true;
    },
    select(res) {
      this.selectVal = res;
    },
    async isChoiceRole() {
      const res = await this.$api.isChoiceRole({ token: this.token });
      if (res.state != 1) {
        this.userHaveRole();
      }
    },
    async choiceRole() {
      const query = {
        goodsId: this.selectVal.id,
        token: this.token,
      };
      const res = await this.$api.choiceRole(query);
      this.shotToast(res.msg);
      if (res.code == 0) {
        this.showTip = false;
        this.monopolyInit();
      }
    },
    goTo() {
      if (this.$route.query.platform) {
        if (this.isiOS) {
          this.bridge.callHandler(
            'jumptoapp',
            { key: 17 },
            function (response) {
              console.log(JSON.stringify(response));
            },
          );
        } else {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: 17 }));
        }
      } else {
        this.$router.push('/category');
      }
    },
    shotToast(msg) {
      this.toastText = msg;
      setTimeout(() => {
        this.toastText = '';
      }, 2000);
    },
  },
};
</script>

<style lang="less" scoped>
.dafuweng {
  width: 375px;
  height: 820px;
  background-image: url(../../assets/images/dafuweng/bg1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 20px;
  .button {
    position: absolute;
    top: 250px;
    left: 15px;
    .buttonBg {
      width: 50px;
    }
    .content {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      .shaizi {
        position: absolute;
        top: 15px;
        right: 15px;
        img {
          width: 18px;
        }
      }
      p {
        font-size: 10px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #fff444;
        transform: rotate(90deg);
        position: absolute;
        white-space: nowrap;
        top: 38px;
        left: -8px;
      }
    }
  }
  .group {
    display: flex;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
    background: url(../../assets/images/dafuweng/right.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    justify-content: flex-end;
    padding: 5px 30px 25px 0;
    .groupItem {
      margin-left: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 51px;
      }
    }
  }
  .boxBg {
    height: 270px;
    width: 530px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9;
    transform: translate(-50%, -50%) rotate(90deg);
    background: url(../../assets/images/tianliangle/boxBg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 25px 38px 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .close {
      width: 30px;
      height: 30px;
      border: 1px solid #fff5e9;
      border-radius: 50%;
      position: relative;
      display: block;
      position: absolute;
      right: -50px;
      top: -20px;
      transform: rotate(45deg);
      &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        height: 2px;
        background: #fff5e9;
        display: block;
        content: '';
      }
      &::after {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 80%;
        width: 2px;
        background: #fff5e9;
        display: block;
        content: '';
        visibility: visible;
      }
    }
    .top {
      display: flex;
      align-items: center;
      .left {
        flex: 1;
        display: flex;
        align-items: center;
        min-width: 1px;
        div {
          width: 35px;
          height: 35px;
          img {
            width: 100%;
            height: 100%;
            background: #eae9bb;
            border-radius: 50%;
          }
        }
        span {
          flex: 1;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #775712;
          margin-left: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 1px;
        }
      }
      .title {
        flex: 2;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #775712;
        text-align: center;
      }
      .right {
        flex: 1;
      }
    }
    .middle {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-width: 1px;
      overflow: auto;
      .middleBox {
        display: flex;
        align-items: center;
        .groupItem {
          display: flex;
          flex-direction: column;
          margin-right: 10px;
          user-select: none;
          box-sizing: border-box;
          align-items: center;
          &.active {
            background: #f6ffd8;
            border: 1px solid #93cb50;
          }
          div {
            user-select: none;
            width: 85px;
            height: 90px;
            img {
              user-select: none;
              width: 100%;
              height: 100%;
            }
          }
          span {
            user-select: none;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            padding: 5px;
            color: #775712;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 87px;
            box-sizing: border-box;
          }
        }
      }
      &.info {
        font-size: 15px;
        font-family: PingFang SC;
        color: #897738;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .left {
        font-size: 11px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ff4343;
      }
      .right {
        img {
          width: 121px;
        }
      }
    }
  }
  .boxBg1 {
    width: 450px;
    height: 325px;
    background: #6f7e36;
    border-radius: 5px;
    position: fixed;
    z-index: 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    .close {
      width: 30px;
      height: 30px;
      border: 1px solid #fff5e9;
      border-radius: 50%;
      position: relative;
      display: block;
      position: absolute;
      right: -50px;
      top: -10px;
      transform: rotate(45deg);
      &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        height: 2px;
        background: #fff5e9;
        display: block;
        content: '';
      }
      &::after {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 80%;
        width: 2px;
        background: #fff5e9;
        display: block;
        content: '';
        visibility: visible;
      }
    }
    .bg {
      background: #faffc9;
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 20px;
      box-sizing: border-box;
      .title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        text-align: center;
        color: #775712;
        padding: 20px 0;
      }
      .content {
        flex: 1;
        min-height: 1px;
        overflow: auto;
      }
      .tab {
        display: flex;
        align-items: center;
        padding: 0 15px;
        .tabItem {
          flex: 1;
          justify-content: center;
          align-items: center;
          display: flex;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #7d6635;
          margin: 5px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          img {
            width: 23px;
            height: 23px;
            background: #eae9bb;
            border-radius: 50%;
            margin-right: 5px;
          }
          .left {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .right {
            flex: 1;
            min-width: 1px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &.list {
          &:nth-child(odd) {
            background: #f1ffcf;
          }
          .tabItem {
            &:nth-child(2) {
              color: #9fb639;
            }
            &:last-child {
              color: #879848;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.toast {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
  bottom: 50%;
  transform: translateY(-50%) rotate(90deg);
  left: 70px;
  color: #eee;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
}
</style>
